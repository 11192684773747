html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

@font-face {
  font-family: "coder";
  src: local("coder"), url(font.woff) format("woff");
}

body {
  margin: 0;
  font-family: "coder", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body::-webkit-scrollbar {
  display: none;
}

body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

html {
  background-color: "#4C4C4C";
}

div {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-user-select: none;
}

body {
  font-family: Sans-Serif;
  background: #FFFFFF;
}

.header {
  margin-top: 25px;
  padding: 0 30px;
}

.container {
  max-width: 1280px;
  margin: 0 auto;
}



.soldout-info {
  font-size: 28px;
  font-weight: bolder;
  text-align: center;
}

.soldout-info h3 {
  font-size: 42px;
  margin-bottom: 60px;
}
.desklay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  margin-top: 40px;
}

.header .container {
  text-align: center;
  display: grid;
  align-items: flex-start !important;

  grid-auto-flow: column;
  place-items: center;
  gap: 30px;
  padding: 0 0px;
}
img.btntn {
  width: 130%;
}
#small-logo {
  position: absolute;
  top: 10px;
  left: 10px;
  max-width: 65px;
  z-index: 5;
  border-radius: 50%;
}

#logo {
  width: 100%;
}

.logo-wrapper {
  width: 100%;
  z-index: 2;
}

a {
  color: #000;
}

a:hover {
  color: #3160FF;
}

#logo img {
  width: 100%;
  max-width: 350px;
}

.button {
  border: 6px solid #000;
  border-radius: 10px;
  padding: 20px 35px;
  text-decoration: none;
  color: #000;
  text-transform: uppercase;
  font-size:15px;
  max-width: 250px;
  line-height: 1.3em;
  font-weight: 600;
  letter-spacing: 1px;
  text-align: center;
  transition: all 0.3s ease 0s;
}

.button:hover {
  border-color: #3160FF;
  color: #3160FF;
}

#mint-button:hover {
  border-color: #FF0000;
  color: #FF0000;
}

.button-info {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;

}

.button-info, .mint-input{
  display: none !important;
}
.description {
  text-align: center;
  font-size: 16px;
  width: 100%;
  max-width: 320px;
}

.button-info .price {
  font-size: 24px;
  font-weight: 600;
  text-align: center;
}
.primary{
  padding: 0 30px;
}
.primary .container {
  text-align: center;
  display: grid;

  grid-auto-flow: column;
  place-items: center;
  grid-gap: 30px;
  gap: 30px;
  padding: 0 0px;


  display: grid;
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-flow: column;
  grid-gap: 30px;
  gap: 30px;
  padding: 0 0px;
  align-items: flex-start;
  padding-top: 30px;
}

.primary .main {
  display: grid;
  justify-items: center;
  gap: 30px;
}
.primary .container .main img {
  width: 100%;
}

.big-image {
  max-width: 100%;
  margin-top: -380px;
}

.mint-image {
  max-width: 100%;
  display: none !important;
}
@media only screen and (max-width: 819px) {
  .big-image{
    display: none !important;
  }
  #small-logo {
    position: relative;
    top: -5px;
    left: auto;
    max-width: 65px;
    z-index: 5;
    margin: auto;
    display: block;
}
  .mint-image {
    display: block !important;
    max-width: 120%;
    margin-left: -10%;
    margin-bottom: -35px;
    margin-top: 6px;
    margin-right: -10%;
  }
  .side {
    padding-left: 0px !important;
  }
  .haswallet{
    margin-top: 0px !important;
  }
  .desklay {
    margin-top: 0px;
  }
}
.edition-info {
    max-width: 253px;
    margin-top: 20px;
}
.haswallet{
  border: 3px solid #000;
  border-radius: 10px;
  padding: 14px 20px;
  max-width: 90%;
  margin: auto;
  font-weight: 800;
  font-size: 17px;
  text-align: left;
  width: 100%;
}
.disconnectx{
  font-size: 12px;
  margin-top: 5px;
  border-bottom: 1px solid #ccc;
  font-weight: 400;
  width: fit-content;
  cursor: pointer;
}
.side {
  display: grid;
  grid-template-columns: auto;
  place-items: center;
  align-content: center;
  gap: 20px;    
}
.styledbotcreds {
  margin-top: 20px;
  text-decoration: none;
}
.styledbotcreds a{
  font-size: 20px;
  line-height: 40px;
  text-decoration: none;
}
.saledeets{
  font-size: 40px;
  padding-top: 45px;
  font-weight: bold;
}
.saledeets div{
  font-size: 20px;
  display: block;
}

select.mint-input {
  border: 6px solid #000;
  font-size: 28px;
  border-radius: 10px;
  max-width: 180px;
  text-align: center;
  padding: 25px 30px;
  font-weight: 600;
}
.bottom {
  grid-column: 1 / span 3;
  padding: 0 60px;
  margin-bottom: 60px;
}
.bringback {
  height: 110px;
}
.bottom #bottom-text {
  width: 100%;
}

#mint-button {
  font-size: 28px;
  padding: 30px 45px;
  letter-spacing: 1px;
}

.mint-info {
  display: grid;
  gap: 10px;
  place-items: center;
  font-size: 24px;
}

.minted-description.red-description {
  color: #FF0000; /* to be removed */
}

.minted-description.red-description a{
  color: #FF0000; /* to be removed */
}

#minted {
  width: 176px;
  height: 37px;
  display: block;
}

#minted img {
  width: 100%;
}

.show-mobile {
  display: none;
}

#alert-bar {
  -webkit-box-align: center;
  align-items: center;
  background: rgba(255,255,255,0.9);
  border-bottom: 2px solid #000;
  color: #000000;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  padding: 5px 0;
  font-weight: 500;
  min-height: 48px;
  height: auto;
  position: absolute;
  width: 100%;
  top: 0px;
  opacity: 0;
  transition: all 0.3s ease 0s;
  z-index: 10000;
  font-weight: 700;
  line-height: 47px;
  font-size: 18px;
  letter-spacing: 0.5px;
}

#alert-bar a {
  color: #3160FF;
  display: inline-block;
}

#alert-bar .alert-content {
  margin-right: 20px;
  line-height: 1.5em;
}

#connect-wallet i {
  margin-left: 10px;
}

#close-alert-bar {
  width: 15px;
}

#close-alert-bar img {
  width: 100%;
}

.alert-content a {
  text-decoration: underline;
}

@media (max-width: 680px) {
  #alert-bar {
    position: relative;
    display: none;
    height: auto;
  }

  .alert-content {
    padding-left: 20px;
    padding-right: 20px;
    line-height: 1.5em;
  }

  #close-alert-bar {
    position: absolute;
    right: 10px;
  }
  .primary .container, .header .container {
    grid-template-columns: auto;
    grid-auto-columns: unset;
    grid-auto-flow: unset;
  }
  

}

.contract-info {
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 30px;  
  margin-top: 20px;
}

.contract-info a {
  color: #000;
  text-decoration: underline;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 1px;
}

.contract-info a:hover {
  color: #3160FF;
}
.containerf{
  width: 100%;
  display: block;
  margin-top: 80px;
  padding: 20px 20px;
}
textarea{
  height: 300px;
  width: 100%;
  display: block;
  margin-left: 0;
  margin-top: 16px;
}